<template>
  <div class="cart">
    <NavHeader />

    <div class="content_container">
      <el-page-header
        @back="$util.goBack"
        content="订单结算"
        style="line-height: 50px"
      >
      </el-page-header>
      <div
        class="border"
        style="padding: 10px"
      >
        <div class="cont">
          <dl class="title">
            <dt>收货人信息</dt>
            <dd
              @click="toAddress"
              class="hover"
            >添加地址</dd>
          </dl>
          <el-radio-group
            v-if="addressList.length"
            v-model="address_id"
            @change="addressChange"
          >
            <p
              class="list"
              v-for="(item, index) in addressList"
              :key="index"
            >
              <el-radio :label="item.id">
                <span>{{ item.receiver }}</span>
                <span>{{
                    (item.get_region || {}).name +
                    " " +
                    (item.getcity || {}).name +
                    " " +
                    (item.getarea || { name: "" }).name
                  }}
                </span>
                <span> {{ item.address }}</span>
                <span> {{ item.tel }}</span>
              </el-radio>
            </p>
          </el-radio-group>
          <p
            v-else
            style="text-align: center"
          >暂无收货地址 请先 添加地址</p>
        </div>

        <!-- <el-divider></el-divider> -->
        <div class="cont">
          <dl class="title">
            <dt>是否开具发票</dt>
            <dd
              class="hover"
              @click="invoice_add = true"
            >添加发票信息</dd>
          </dl>
          <div>
            <el-radio
              :label="1"
              v-model="invoice"
            > <span> 是</span> </el-radio>

            <el-radio
              :label="0"
              v-model="invoice"
            > <span> 否</span> </el-radio>
          </div>
          <div
            v-if="invoice"
            class="invoice"
          >
            <dl v-if="!invoiceList.length">
              <dt>暂无发票信息可选，请添加发票信息</dt>
            </dl>
            <table v-else>
              <tr
                v-for="(item, index) in invoiceList"
                :key="index"
              >
                <td>
                  <el-radio
                    v-model="invoice_id"
                    :label="item.invoice_id"
                  ><i style="width: 0; padding: 0"></i></el-radio>
                </td>
                <td style="width: 100px">电子普通发票</td>
                <td style="width: 80px">
                  <template v-if="item.invoice_code"> 企业</template>
                  <template v-else> 个人</template>
                </td>
                <td style="width: 300px">{{ item.invoice_title }}</td>
              </tr>
            </table>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="car_list cont">
          <p class="title">产品清单</p>
          <div style="padding: 10px">
            <ul class="first">
              <li>商品信息</li>
              <li>商品规格</li>
              <li>单价</li>
              <li>数量</li>
              <li>金额</li>
            </ul>
          </div>
          <ul
            v-for="(item, index) in shoplist"
            :key="index"
            class="list_shop"
          >
            <li>
              <div class="shop_name">
                店铺: <span style="color: #999">{{ item.shop_name }}</span>
              </div>
              <ol
                v-for="(goods, gIndex) in item.carts_list"
                :key="gIndex"
                class="list_product first"
              >
                <li class="list-goods-name">
                  <img :src="$config.baseUrl + goods.files_path" />

                  <p>
                    {{ goods.goods_name }}
                  </p>
                </li>
                <li>{{ JSON.parse(goods.option).spec }}</li>
                <li>
                  <strong v-if="JSON.parse(goods.option).id">
                    ￥{{
                      parseFloat(JSON.parse(goods.option).price).toFixed(2)
                    }}
                  </strong>
                  <strong v-else-if="goods.isprice == 2">
                    ￥ {{ goods.max_price }}
                  </strong>
                  <strong v-else-if="goods.act_status == 1">
                    ￥{{ goods.act_price }}
                  </strong>
                  <strong v-else>
                    ￥{{ parseFloat(goods.sales_price / 100).toFixed(2) }}
                  </strong>
                </li>
                <li>
                  <span> {{ goods.quantity }}</span>
                </li>
                <li>
                  <strong
                    v-if="JSON.parse(goods.option).id"
                    style="
                      color: #ff0036;
                      margin: -4px 0 0 2px;
                      line-height: 40px;
                      font-size: 16px;
                    "
                  >
                    ￥{{
                      parseFloat(
                        JSON.parse(goods.option).price * goods.quantity
                      ).toFixed(2)
                    }}
                  </strong>
                  <strong v-else-if="goods.isprice == 2">
                    ￥ {{ goods.max_price }}
                  </strong>
                  <strong
                    v-else-if="goods.act_status == 1"
                    style="
                      color: #ff0036;
                      margin: -4px 0 0 2px;
                      line-height: 40px;
                      font-size: 16px;
                    "
                  >
                    ￥{{
                      (goods.act_price * goods.quantity).toFixed(2)
                    }}
                  </strong>
                  <strong
                    v-else
                    style="
                      color: #ff0036;
                      margin: -4px 0 0 2px;
                      line-height: 40px;
                      font-size: 16px;
                    "
                  >
                    ￥{{
                      parseFloat(
                        (goods.sales_price / 100) * goods.quantity
                      ).toFixed(2)
                    }}
                  </strong>
                </li>
              </ol>
              <dl class="beizhu">
                <dd>
                  <!-- <p>
                    <el-checkbox v-model="fapiao">开具电子发票</el-checkbox>
                  </p> -->
                  <p>
                    <span>订单备注： </span>
                    <el-input
                      type="textarea"
                      :rows="3"
                      style="width: 400px"
                      v-model="item.mark_info"
                      placeholder="请输入备注"
                    ></el-input>
                  </p>
                </dd>
                <dd class="coupon">
                  <div style="text-align:right">
                    <p
                      style="display:inline-block"
                      v-if="!item.coupon.length||pisprice==2"
                    >
                      <span>店铺优惠券:</span><span>暂无可用</span>
                    </p>
                    <p
                      style="display:inline-block"
                      v-else
                    >
                      <span>店铺优惠券:</span>
                      <el-select
                        v-model="item.chosecouponid"
                        @change="
                        chooseShopcoupon(item)
                      
                      "
                      >
                        <el-option
                          v-for="i in item.coupon"
                          :key="i.id"
                          :label="i.money"
                          :value="i.shop_receive_id"
                        >
                          {{ i.name }} {{ i.money }} <i v-if="i.money">元</i>
                        </el-option>
                      </el-select>
                      <span>
                        <template v-if="item.chosecoupon.money">-{{ item.chosecoupon.money }}
                        </template>
                        <template v-else>{{ item.chosecoupon.money }}无
                        </template>
                      </span>
                    </p>
                  </div>

                  <p><i> </i><i> 运费:{{item.shipping_money.toFixed(2)}}</i> </p>
                </dd>

              </dl>
              <div
                class="count"
                v-if='item.isprice==2'
              >
                店铺合计(含运费)：<span> {{      parseFloat( item.count)+item.shipping_money  }}</span>
              </div>
              <div
                class="count"
                v-else
              >
                店铺合计(含运费)：<span> {{     (parseFloat( item.count)+item.shipping_money).toFixed(2) }}</span>
              </div>

            </li>
          </ul>
          <el-divider></el-divider>

          <div class="ptyhq">

            <span v-if="!couponList.length||pisprice==2">
              <span>平台优惠券:</span><span style="margin-left:10px">暂无可用</span>
            </span>
            <span v-else>
              <span>平台优惠券:</span>
              <el-select
                v-model="coupon_id"
                @change="
                        chosecoupon = couponList.find(
                          (a) => a.receive_coupon_id == coupon_id
                        )
                      "
              >
                <el-option
                  v-for="i in couponList"
                  :key="i.id"
                  :label="i.money"
                  :value="i.id"
                >
                  {{ i.name }} {{ i.money }}

                  <i v-if="i.money">元</i>
                </el-option>
              </el-select>
              <span>
                <template v-if="chosecoupon.money">-{{ chosecoupon.money }}
                </template>
                <template v-else>{{ chosecoupon.money }}无
                </template>
              </span>
            </span>
            <p v-if="this.$route.query.cycle1">发货周期：内地：{{this.$route.query.cycle1}} <span v-if="this.$route.query.cycle2">港澳台:{{this.$route.query.cycle2}}</span> </p>
          </div>

          <ul class="last_tr">
            <li>
              <span class="tr_p1">共</span>
              <span class="tr_p2"> {{ totalQantity }} </span>个
            </li>

            <li v-if='pisprice==2'>
              <span class="tr_p1">合计：</span>
              <span class="tr_p2">{{ totalMoney  }}</span>
            </li>

            <li v-else>
              <span class="tr_p1">合计：</span>
              <span class="tr_p2">{{ totalMoney }}</span>
            </li>
            <li
              class="checked_block"
              v-if="totalMoney > 0"
              @click="addOrder"
            >
              提交订单
            </li>
            <li
              class="checked_none"
              v-else
            >提交订单</li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog
      title="添加地址"
      :visible.sync="dialogVisible_add"
      width="70%"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="140px"
        class="ruleForm"
      >
        <el-form-item
          label="收货人"
          prop="receiver"
        >
          <el-input v-model="ruleForm.receiver" />
        </el-form-item>

        <el-form-item
          label="联系电话"
          prop="tel"
        >
          <el-input v-model="ruleForm.tel" />
        </el-form-item>
        <el-form-item
          label="地区"
          prop="city_id"
        >
          <el-select
            style="width: 25%"
            v-model="ruleForm.province_id"
            filterable
            placeholder="请选择"
            @change="getCity(true)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width: 25%"
            v-model="ruleForm.city_id"
            filterable
            placeholder="请选择"
            @change="getArea(true)"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width: 25%"
            v-model="ruleForm.area_id"
            filterable
            placeholder="请选择"
            @change="getStreet(true)"
          >
            <el-option
              v-for="item in options3"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width: 25%"
            v-model="ruleForm.street_id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options4"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="地址"
          prop="address"
        >
          <el-input v-model="ruleForm.address" />
        </el-form-item>
        <el-form-item
          label="设为默认地址"
          prop=""
        >
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="ruleForm.status"
            active-color="#13ce66"
            inactive-color="#eee"
          >
          </el-switch>
        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible_add = false">取 消</el-button>
        <el-button
          type="primary"
          @click="add_submitForm('ruleForm')"
        >提交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="发票信息"
      :visible.sync="invoice_add"
      width="40%"
    >
      <el-form
        ref="ruleForm2"
        :model="ruleForm2"
        :rules="rules2"
        label-width="140px"
        class="ruleForm2"
      >
        <el-form-item label="发票类型"> 普通发票 </el-form-item>
        <el-form-item
          label="发票抬头类型"
          prop="title"
        >
          <el-radio
            v-model="ruleForm2.type"
            label="1"
          >个人</el-radio>
          <el-radio
            v-model="ruleForm2.type"
            label="2"
          >企业</el-radio>
        </el-form-item>

        <el-form-item
          label="发票抬头名称"
          prop="title"
        >
          <el-input v-model="ruleForm2.title" />
        </el-form-item>

        <el-form-item
          label="纳税人识别号"
          prop="invoice_code"
          v-if="ruleForm2.type == 2"
        >
          <el-input v-model="ruleForm2.invoice_code" />
        </el-form-item>
        <el-form-item
          label="更多选填项"
          v-if="ruleForm2.type == 2"
        >
          <el-collapse>
            <el-collapse-item
              title="填写单位地址、电话"
              name="1"
            >
              <el-row>
                <el-col :span="4">单位地址</el-col>
                <el-col :span="20">
                  <el-input v-model="ruleForm2.address"></el-input>
                </el-col>
                <el-col :span="4">单位电话</el-col>
                <el-col :span="20">
                  <el-input v-model="ruleForm2.phone"></el-input>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>

        <el-form-item label="发票内容"> 商品明细 </el-form-item>

        <el-form-item
          label="设为默认抬头"
          prop=""
        >
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="ruleForm2.status"
            active-color="#13ce66"
            inactive-color="#eee"
          >
          </el-switch>
        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="invoice_add = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addInvoice('ruleForm2')"
        >提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      couponList: [],
      chosecoupon: {},
      coupon_id: "",

      Postage: 0,
      pisprice: "",
      pmax_price: "",
      invoice_add: false,
      invoice_id: "",
      invoice: 0,
      fapiao: false,
      options: [],
      options2: [],
      options3: [],
      options4: [],
      payment_type: "",
      addressList: [],
      address_radio: 0,
      totalPrice: [],
      lastTaotalPrice: 0,
      dialogVisible_add: false,
      address_id: undefined,

      ruleForm: {
        receiver: "",
        tel: "",
        province_id: "",
        city_id: "",
        area_id: "",
        street_id: "",
        address: "",
        zip_code: "0",
        status: 0
      },
      rules: {
        receiver: [
          {
            required: true,
            message: "请输入接收者姓名",
            trigger: "blur"
          }
        ],
        tel: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur"
          },
          {
            pattern: this.$validate.mobile,
            message: "请填写正确的电话",
            trigger: "change"
          }
        ],
        city_id: [
          {
            required: true,
            message: "请选择地区",
            trigger: "blur"
          }
        ],
        address: [
          {
            required: true,
            message: "请填写地址",
            trigger: "blur"
          }
        ]
      },
      ruleForm2: {
        type: "1",
        title: "",
        invoice_code: "",
        phone: "",
        address: "",
        status: 0
      },
      rules2: {
        type: [
          {
            required: true,
            message: "请选择抬头类型",
            trigger: "blur"
          }
        ],
        title: [
          {
            required: true,
            message: "请填写抬头名称",
            trigger: "blur"
          }
        ],
        invoice_code: [
          {
            required: true,
            message: "请填写纳税人识别号",
            trigger: "blur"
          }
        ]
      },
      options: "",

      baseUrl: this.$config.baseUrl,
      invoiceList: [],
      show: false,
      addressinfo: "",
      option: "",
      option_model: "快递",
      shipping_method: 100,
      defaultValueData: null,
      isVisible_model: false,
      listData_model: [
        [
          {
            label: 100,
            value: "快递"
          },
          {
            label: 1,
            value: "自提"
          }
        ]
      ],
      address: {},
      shoplist: [],

      couponId: undefined,
      couponTempId: undefined,
      couponItem: {},
      couponItem1: ""
    };
  },
  computed: {
    totalMoney() {
      //总计(减去平台优惠券)

      return (this.totalAllMoney - (this.chosecoupon?.money || 0)).toFixed(2);
    },
    totalMoney2() {
      //总计(只所有产品的价格和

      let money = 0;
      let data = this.shoplist;
      data.forEach(item => {
        item.carts_list.forEach(goods => {
          if (goods.spec_id) {
            //多型号产品
            money += JSON.parse(goods.option).price * goods.quantity;
          } else if (goods.isprice == 2) {
            //多议价产品
            money += goods.max_price * goods.quantity;
          } else if (goods.act_status == 1) {
            //活动产品
            money += goods.act_price * goods.quantity;
          } else {
            //普通产品
            money += (goods.sales_price / 100) * goods.quantity;
          }
        });
      });

      return money;
    },
    totalAllMoney() {
      //总计
      let money = 0;
      let data = this.shoplist;
      data.forEach(item => {
        item.carts_list.forEach(goods => {
          if (goods.spec_id) {
            //多型号产品
            money += JSON.parse(goods.option).price * goods.quantity;
          } else if (goods.isprice == 2) {
            //多议价产品
            money += goods.max_price * goods.quantity;
          } else if (goods.act_status == 1) {
            //活动产品
            money += goods.act_price * goods.quantity;
          } else {
            //普通产品
            money += (goods.sales_price / 100) * goods.quantity;
          }
        });

        money = money - (item.chosecoupon?.money || 0) + item.shipping_money;
      });

      return money;
    },

    totalQantity() {
      let qantity = 0;
      let data = this.shoplist;
      data.forEach(item => {
        item.carts_list.forEach(goods => {
          goods.quantity = parseInt(goods.quantity);
          qantity = qantity + goods.quantity;
        });
      });

      return qantity;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  
    
     if(this.$store.state.member.id){ 
       this.getInvoice();
      this.getProuduct();}
   
  },
  methods: {
    addressChange() {
      let address = this.addressList.find(a => a.id == this.address_id);
      this.province_id = address.province_id;
      this.addressinfo =
        (address.get_region || {}).name +
        " " +
        (address.getcity || {}).name +
        " " +
        (address.getarea || { name: "" }).name +
        " " +
        (address.getstreet || { name: "" }).name;

      this.shoplist.forEach(a => {
        this.getPostage(a);
      });
    },
    getPostage(item) {
      let goodsid = item.carts_list.map(m => m.goods_id);
      let weight_total = 0;
      item.carts_list.forEach(i => {
        weight_total += i.goods_weight_total;
      });

      this.$get("home/shipping", {
        shop_id: item.shop_id,
        province_id: this.province_id,
        goods_weight_total: weight_total,
        count: item.count,
        goods_id: goodsid.join(",")
      }).then(res => {
        if (res.items[0]) {
          item.shipping_money = parseFloat(res.items[0].total_fee); }

          //查询店铺优惠券

          this.$post("home/shop_coupon_receive/can", {
            member_id: this.$store.state.member.id,
            money: item.count,

            shop_id: item.shop_id,
            type: undefined
          }).then(res => {
            if (!res.datas.data.length) return;
            item.coupon = res.datas.data;
            if (item.coupon.length) {
              let a = {
                name: "不用店铺抵用券",
                id: 0,
                money: "",
                shop_receive_id: 0
              };

              item.coupon.push(a);
            }
            item.chosecouponid =
              this.pisprice == 2 ? null : item.coupon[0].shop_receive_id;
            item.chosecoupon = this.pisprice == 2 ? null : item.coupon[0];
            item.count = (
              item.count -
              (this.pisprice == 2 ? 0 : item.chosecoupon.money || 0)
            ).toFixed(2);

            this.chooseShopcoupon(item);
          });

          //查询平台优惠券

          this.$post("home/coupon_receive/can", {
            member_id: this.$store.state.member.id,
            money: this.totalMoney2
          }).then(res => {
            if (!res.datas.data.length) return;
            this.couponList = res.datas.data;
            if (this.couponList.length) {
              let a = { name: "不用平台抵用券" };
              this.couponList.push(a);
            }
            this.coupon_id =
              this.pisprice == 2 ? null : this.couponList[0].receive_coupon_id;
            this.couponList[0].money = parseInt(this.couponList[0].money);
            this.chosecoupon = this.pisprice == 2 ? null : this.couponList[0];
          });
       
      });
    },

    addInvoice(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            member_id: this.$store.state.member.id,
            title: this.ruleForm2.title,
            invoice_code: this.ruleForm2.invoice_code,
            phone: this.ruleForm2.phone,
            address: this.ruleForm2.address,
            status: this.ruleForm2.status
          };
          this.$post("home/invoice", data).then(res => {
            this.invoice_add = false;
            this.getInvoice();
          });
        }
      });
    },
    getInvoice() {
      this.$get("home/invoice", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.invoiceList = res.items.data;
        if (this.invoiceList.length) {
          this.invoice_id = this.invoiceList[0].invoice_id;
        }
      });
    },
    getProvince() {
      this.$post("home/regions/index", {
        level: 1,
        pid: 0
      }).then(res => {
        this.options = res;
      });
    },
    getCity(isChange) {
      if (isChange) {
        this.ruleForm.city_id = undefined;
        this.ruleForm.area_id = undefined;
        this.ruleForm.street_id = undefined;
        this.options2 = [];
        this.options3 = [];
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 2,
        pid: this.ruleForm.province_id
      }).then(res => {
        this.options2 = res;
      });
    },
    getArea(isChange) {
      if (isChange) {
        this.ruleForm.area_id = undefined;
        this.ruleForm.street_id = undefined;
        this.options3 = [];
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 3,
        pid: this.ruleForm.city_id
      }).then(res => {
        this.options3 = res;
      });
    },
    getStreet(isChange) {
      if (isChange) {
        this.ruleForm.street_id = undefined;
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 4,
        pid: this.ruleForm.area_id
      }).then(res => {
        this.options4 = res;
      });
    },

    //添加地址
    add_submitForm(formName) {
    
       this.$refs[formName].validate(valid => {
        if (valid) {
          this.$post("home/address", {
            status: this.ruleForm.status,
            member_id: this.$store.state.member.id,
            receiver: this.ruleForm.receiver,
            tel: this.ruleForm.tel,
            province_id: this.ruleForm.province_id,
            city_id: this.ruleForm.city_id,
            area_id: this.ruleForm.area_id || 0,
            street_id: this.ruleForm.street_id || 0,
            address: this.ruleForm.address,
            zip_code: false
          }).then(() => {
            this.dialogVisible_add = false;
            this.$message("添加成功");
            this.getAddress();
          });
        }
      });
    },
    //订单添加
    addOrder() {
      if (!this.address_id) {
        this.$message.warning("请填写收货地址");
        return;
      }
      let title = "";
      let code = "";
      let phone = "";
      let address = "";
      this.invoiceList.forEach(o => {
        if (o.invoice_id == this.invoice_id) {
          title = o.invoice_title;
          code = o.invoice_code;
          phone = o.invoice_reg_phone;
          address = o.invoice_goto_addr;
        }
      });
      let data = {
        addressinfo: this.addressinfo,
        member_id: this.$store.state.member.id,
        address_id: this.address_id,
        payment_type: "1",
        shipping_method: this.shipping_method,
        isprice: this.pisprice,
        // pmax_price: this.pmax_price,
        coupon_id: this.chosecoupon ? this.chosecoupon.receive_coupon_id : "",
        coupon_receive_id: this.coupon_id,
        code: "",
        goods: this.shoplist.map(a => {
          a.carts_list.forEach(b => {
            if (b.spec_id) {
              b.last_price = JSON.parse(b.option || "{}").price * 100;
              b.sales_price = b.last_price;
            }

            b.pisprice = this.pisprice;
            b.isprice = this.pisprice;
            b.pmax_price = this.pmax_price;
            b.goods_shop_name = a.shop_name;
          });
          a.shop_coupon_id =
            this.pisprice == 2 ? "" : a.chosecoupon.receive_coupon_id;
          a.shop_coupon_receive_id =
            this.pisprice == 2 ? "" : a.chosecoupon.shop_receive_id;
          a.invoice = this.invoice;
          a.title = title;

          a.code = code;
          a.phone = phone;
          a.address = address;
          a.pisprice = this.pisprice;
          a.isprice = this.pisprice;
          a.pmax_price = this.pmax_price;
          a.shop_total =
            this.pisprice == 2
              ? a.count + a.pmax_price + a.shipping_money
              : a.count + a.shipping_money;
          return a;
        })
      };
      this.$post("home/order", data).then(res => {
        let list = [];
        this.shoplist.forEach(shop => {
          shop.carts_list.forEach(goods => {
            list.push({
              cart_id: goods.cart_id,
              goods_id: goods.goods_id
            });
          });
        });
        this.$store.dispatch("delCarData", list);
        if (this.pisprice == 2) {
          this.$router.push({
            path: "/priceMessage2",
            query: {
              id: this.shoplist[0].carts_list[0].goods_id
            }
          });
        } else {
          this.$router.push({
            path: "payType",
            query: {
              order_master_id: res.order_id,
              totalMoney: this.totalMoney
            }
          });
        }
      });
    },
    //新增地址
    toAddress() {
      this.ruleForm = { street_id: undefined };
      this.dialogVisible_add = true;

      this.getProvince();
      this.getCity();
      this.getArea();
      this.getStreet();
    },

    //获取地址
    getAddress(item) {
      this.$get("home/address", {
        member_id: this.$store.state.member.id
      }).then(res => {
        this.addressList = res.items;
        this.address_id = this.addressList[0].id;
        this.province_id = this.addressList[0].province_id;
        this.getPostage(item);

        this.addressinfo =
          (this.addressList[0].get_region || {}).name +
          " " +
          (this.addressList[0].getcity || {}).name +
          " " +
          (this.addressList[0].getarea || {}).name +
          " " +
          (this.addressList[0].getstreet || {}).name;
      });
    },
    //获取购物车里的产品
    getProuduct() {
      this.shoplist = JSON.parse(sessionStorage.getItem("cartDate") || "[]");
      console.log("  this.shoplist", this.shoplist);

      this.shoplist.forEach(item => {
        if (item.carts_list[0].isprice == 2) {
          this.pisprice = 2;
          this.pmax_price = item.carts_list[0].max_price;
        }

        // this.getPostage(shop_id);

        this.$set(item, "count", 0); //每个店铺产品小计
        this.$set(item, "coupon", []); //每个店铺优惠券字段
        this.$set(item, "mark_info", "");
        this.$set(item, "chosecouponid", ""); //每个选中店铺使用的优惠券id
        this.$set(item, "chosecoupon", {}); //每个选中店铺使用的优惠券
        this.$set(item, "shipping_money", 0); //每个选中店铺使用的优惠券

        let totalMoney = 0;

        item.carts_list.forEach(i => {
          totalMoney +=
            (this.pisprice == 2
              ? i.max_price
              : i.act_status == 1
              ? i.act_price
              : JSON.parse(i.option).price || i.sales_price / 100) * i.quantity;
        });
        item.count = totalMoney.toFixed(2);

        this.getAddress(item);
      });
    },
    chooseShopcoupon(item) {
      item.chosecoupon = item.coupon.find(
        a => a.shop_receive_id == item.chosecouponid
      );
      let totalMoney = 0;
      item.carts_list.forEach(i => {
        totalMoney +=
          (this.pisprice == 2
            ? i.max_price
            : i.act_status == 1
            ? i.act_price
            : JSON.parse(i.option).price || i.sales_price / 100) * i.quantity;
      });
      item.count = totalMoney.toFixed(2);
      item.count = (
        item.count - (this.pisprice == 2 ? 0 : item.chosecoupon.money || 0)
      ).toFixed(2);
      this.getPostage(item);
    }
  },
  components: {
    NavHeader
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";

::v-deep .el-divider--horizontal {
  margin: 0;
}
::v-deep .el-collapse {
  border: none !important;
}
::v-deep .el-collapse-item__wrap {
  border: none !important;
}
.ptyhq {
  text-align: right;
  padding: 10px 0;
  margin-bottom: 20px;
}
.invoice {
  dl {
    width: 300px;
    padding: 10px !important;
    dd {
      color: red;
    }
  }
  table {
    border-collapse: collapse;
    margin-left: 20px;
    td {
      padding-top: 10px;
      border-collapse: collapse;
    }
  }
}
.coupon {
  p {
    text-align: right;
    color: #3c3c3c;
    @include center;
    @include space-between;
    vertical-align: middle;
    span {
      margin-left: 10px;

      box-sizing: border-box;
    }
    .el-select {
      vertical-align: middle;
      width: 200px;
    }
  }
}
.content_container {
  margin: 0 auto;
  margin-top: 10px !important;
  background: #fff;
}
.beizhu {
  @include space-between;
  dd:nth-child(2) {
    width: 40%;
    text-align: right;
  }
  p {
  }
  dd:nth-child(1) {
    p {
      @include row;
    }
    width: 70%;
    vertical-align: middle;
    span {
      display: block;

      line-height: 40px;
    }
  }
}
.count {
  text-align: right;
  span {
    color: red;
    font-size: 20px;
    font-weight: bold;
  }
}
.pay {
  @include row;
}
.cont {
  margin-bottom: 10px;
  // padding: 10px 0;
  .title {
    line-height: 40px;
  }
  dl {
    padding: 5px;
    display: flex;
  }
  dt {
    margin-right: 50px;
    font-weight: bold;
  }
  .list {
    padding: 5px;
  }
  dd {
    color: #f22d00;
  }
}
.shop_name {
  @include row;
  line-height: 35px;
  height: 35px;
  vertical-align: middle;
  font-weight: bold;
}
.Promotion {
  margin-top: 30px;
}

.cart {
  width: 100%;
  // margin: 0 auto;
  // width: 1226px;

  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;
    position: relative;
    margin-left: 5px;
  }

  input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    border: 1px solid #d9d9d9;
  }

  input[type="checkbox"]:checked::before {
    content: "\2713";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    color: #e50232;
    font-size: 13px;
    font-weight: bold;
  }

  .top {
    height: 95px;
    position: relative;

    // line-height: 95px;
    img {
      height: 45px;
      width: 140px;
      float: left;
      margin-top: 25px;
    }

    h1 {
      float: left;
      font-size: 20px;
      line-height: 95px;
    }

    .Search_Goods {
      margin-left: 590px;
      position: absolute;
      top: 28px;
    }
  }

  .allGood {
    ul {
      height: 35px;
      border-bottom: 2px solid #e6e6e6;

      li {
        height: 100%;
        width: 100px;
        color: #f40;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        border-bottom: 2px solid #f40;
      }
    }
  }

  .list_product {
    width: 100%;
    min-height: 70px;
    @include align-center;
    .list-goods-name {
      font-weight: bold;
      @include space-between;
      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border: 1px solid #999;
      }
      p {
        flex: 1;
        line-height: 18px;
      }
    }
  }

  .list_product * {
    display: inline-block;
    vertical-align: middle;
  }

  .list_product li {
    font-size: 10px;
    font-weight: 100;
  }

  .car_list {
    width: 100%;

    .first {
      @include space-between;

      width: 100%;
      box-sizing: border-box;
      height: 30px;
      line-height: 30px;
    }

    .first li:nth-child(1) {
      flex: 1;
    }
    .first li:nth-child(2) {
      width: 15%;
      text-align: center;
    }
    .first li:nth-child(3) {
      width: 15%;
      text-align: center;
    }
    .first li:nth-child(4) {
      text-align: center;
      width: 15%;
    }
    .first li:nth-child(5) {
      text-align: center;
      width: 15%;
    }

    .center_tr {
      height: 130px;
      width: 1188;
      border: 1px solid #ccc;
      margin-bottom: 15px;
      padding: 17px 0 0 0;

      li {
        float: left;
      }
    }

    .center_tr li:nth-child(1) {
      width: 60px;

      input {
        margin: 0 10px 5px 15px;
      }
    }

    .center_tr li:nth-child(2) {
      width: 342px;

      img {
        width: 80px;
        height: 80px;
        float: left;
      }

      span:hover {
        text-decoration: underline;
        color: #f40;
        cursor: pointer;
        float: left;
      }
    }

    .center_tr li:nth-child(3) {
      width: 252px;

      p {
        margin-bottom: 5px;

        span {
          font-size: 12px;
        }
      }

      // text-align: center;
      // width: 359px;
      // padding-left: 220px;
      // color: #3c3c3c;
      // font-weight: 700;
      // font-family: Verdana,Tahoma,arial;
    }

    .center_tr li:nth-child(4) {
      text-align: center;
      width: 107px;
      color: #3c3c3c;
      font-weight: 700;
      font-family: Verdana, Tahoma, arial;
    }

    .center_tr li:nth-child(5) {
      text-align: center;
      width: 120px;

      .el-input-number {
        width: 80px;
        // color:#222;
      }
    }

    .center_tr li:nth-child(6) {
      text-align: center;
      color: #f40;
      font-weight: 700;
      width: 138px;
      font-family: Verdana, Tahoma, arial;
      padding-top: 3px;
    }

    .center_tr li:nth-child(7) {
      text-align: center;
      width: 149px;
      padding-left: 30px;

      span:hover {
        text-decoration: underline;
        color: #f40;
        cursor: pointer;
      }
    }
  }

  .list_shop {
    min-height: 130px;
    width: 100%;
    box-sizing: border-box;
    background: #f7f7f7;
    margin-bottom: 15px;
    padding: 10px;
    display: inline-block;
  }

  .last_tr {
    text-align: right;
    height: 50px;
    line-height: 50px;
    background: #e5e5e5;

    li {
      margin-left: 30px;
      display: inline-block;
      height: 50px;
      line-height: 50px;
    }
  }

  .checked_none {
    height: 100%;
    width: 150px;
    text-align: center;
    background: #b0b0b0;
    cursor: not-allowed;
    color: #fff;
    font-size: 20px;
  }

  .checked_block {
    height: 100%;
    width: 150px;
    text-align: center;
    background: #f22d00;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }

  .last_tr li {
    height: 100%;

    p {
      float: left;
    }

    .tr_p2 {
      width: 150px;
      font-weight: 700;
      font-size: 22px;
      color: #ff0036;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.el-input__inner {
  padding: 0 !important;
  text-indent: 0px !important;
  border-radius: 0 !important;
}

.el-input-number__decrease {
  width: 20px !important;
}

.el-input-number__increase {
  width: 20px !important;
}
</style>
